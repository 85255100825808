<template>
    <v-main>
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="intro"
                    tag="section"
                    class="landing"
                >
                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            style="padding-top: 0"
                        >
                            <v-card
                                class="card-test"
                                v-if="blockTestStart"
                            >
                                <v-row>
                                    <v-col
                                        md="8"
                                        sm="12"
                                    >
                                        <v-card-text
                                            class="mt-5"
                                        >
                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="mb-10"
                                            >
                                                Узнай <span style="color: #659d83">за 7 минут</span> состояние своего
                                                здоровья
                                            </h1>

                                            <p class="sub-title-test">
                                                <v-icon
                                                    color="orange darken-2"
                                                >
                                                    mdi-arrow-right-bold-outline
                                                </v-icon>
                                                Пройди тест, который покажет, в какой области здоровья есть проблемы, и
                                                получи рекомендации специалистов по укреплению и поддержанию здоровья.
                                            </p>

                                            <v-btn
                                                color="amber accent-4"
                                                class="btn-test mt-13 mb-5 px-10 py-5"
                                                @click="clickBtnTest"
                                            >
                                                Пройти тест бесплатно
                                            </v-btn>

                                            <p class="p-block">
                                                Тест разработан немецкими специалистами в области здравоохранения и
                                                питания.
                                            </p>
                                        </v-card-text>
                                    </v-col>

                                    <v-col
                                        md="4"
                                        sm="0"
                                        class="right-start-test"
                                    >
                                        <v-img
                                            src="/img/Lifetakt-Germany.png"
                                            max-width="240px"
                                            class="mt-4 mb-6 ml-16"
                                        />

                                        <v-img
                                            src="/img/test-woman.png"
                                            max-width="400px"
                                            style="margin: 0 auto"
                                            class="mb-10"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest0"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 0/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Только отвечая ЧЕСТНО, можно получить ТОЧНЫЕ результаты
                                            </h1>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest0">ОК</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest1"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 1/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Недостаток энергии, упадок сил
                                            </h1>

                                            <p class="p-quest mb-10">Просыпаетесь вялыми, в течение дня преследует
                                                сонливость, апатия, пропадает интерес к жизни.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest1Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest1No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest2"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 2/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Заболевания более 2 раз в год
                                            </h1>

                                            <p class="p-quest mb-10">Простудные и другие заболевания более 2-х раз в
                                                год.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest2Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest2No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest3"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 3/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Неприятный запах тела или изо рта
                                            </h1>

                                            <p class="p-quest mb-10">Чувствуется через короткое время после душа и
                                                гигиены полости рта.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest3Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest3No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest4"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 4/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Плохое переваривание некоторых продуктов (чувство тяжести)
                                            </h1>

                                            <p class="p-quest mb-10">Постоянные или периодические боли в животе в
                                                течение двух часов после приема пищи.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest4Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest4No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest5"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 5/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Употребление красного мяса
                                            </h1>

                                            <p class="p-quest mb-10">Употребление красного мяса 2 и более раз в
                                                неделю.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest5Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest5No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest6"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 6/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Проблемы с менструальным циклом, в т.ч. болезненная менструация
                                            </h1>

                                            <p class="p-quest mb-10">Менструальный цикл менее 21 дня или более 32 дней.
                                                Менструация сопровождается болями (<strong>Мужчины на этот вопрос отвечают "НЕТ"</strong>).</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest6Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest6No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest7"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 7/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Использование антибиотиков (лекарств)
                                            </h1>

                                            <p class="p-quest mb-10">Применение антибиотиков и других медикаментов более
                                                2 раз в год за последние 3 года.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest7Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest7No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest8"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 8/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Употребление алкоголя (пива)
                                            </h1>

                                            <p class="p-quest mb-10">Употребление алкоголя (пива) более 1 раза в
                                                неделю.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest8Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest8No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest9"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 9/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Частые перепады настроения
                                            </h1>

                                            <p class="p-quest mb-10">Вы не в состоянии поддерживать свой эмоциональный
                                                фон ровным в течении дня.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest9Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest9No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest10"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 10/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Аллергия
                                            </h1>

                                            <p class="p-quest mb-10">Высыпания, зуд, отеки, насморк.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest10Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest10No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest11"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 11/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Темные круги или отечность под глазами
                                            </h1>

                                            <p class="p-quest mb-10">Наличие припухлостей или темных кругов под
                                                глазами.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest11Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest11No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest12"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 12/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Курение (в т.ч. пассивное)
                                            </h1>

                                            <p class="p-quest mb-10">Курите регулярно или после употребления алкоголя в
                                                кругу друзей, в том числе частое присутствие среди курящих.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest12Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest12No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest13"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 13/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Трудности с концентрацией внимания, плохое запоминание
                                            </h1>

                                            <p class="p-quest mb-10">Рассеянность, страдает краткосрочная память.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest13Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest13No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest14"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 14/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Дискомфорт после еды (изжога, газообразование)
                                            </h1>

                                            <p class="p-quest mb-10">Хотя бы один раз в день, а особенно после приёма
                                                определенного типа пищи.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest14Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest14No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest15"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 15/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Нервная обстановка, частые стрессы
                                            </h1>

                                            <p class="p-quest mb-10">Кроме явных стрессовых ситуаций присутствует
                                                чувство вины, обиды, переживания, тревога.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest15Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest15No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest16"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 16/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Дефекты кожи или неудовлетворительный цвет кожи лица
                                            </h1>

                                            <p class="p-quest mb-10">Высыпания, угри, пигментные пятна, папилломы на
                                                коже лица.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest16Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest16No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest17"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 17/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Употребление сладостей или полуфабрикатов (в т.ч. фаст-фуд)
                                            </h1>

                                            <p class="p-quest mb-10">Частое употребление десертов во время приема пищи,
                                                перекусываете сладостями. Больше употребляете переработанную пищу,
                                                нежели сырую. Употребление фаст-фуда.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest17Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest17No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest18"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 18/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Употребление молочных продуктов более 2 раз в неделю
                                            </h1>

                                            <p class="p-quest mb-10">Употребление молочных продуктов и их производных
                                                (творога, масла, сыров и др.) более 2 раз в неделю.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest18Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest18No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest19"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 19/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Чувство апатии, вялости, депрессия
                                            </h1>

                                            <p class="p-quest mb-10">Продолжительное время не можете выйти из таких
                                                состояний.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest19Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest19No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest20"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 20/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Сон, не приносящий отдыха, бессонница
                                            </h1>

                                            <p class="p-quest mb-10">Не можете заснуть в первые 10 минут или часто
                                                просыпаетесь от любого шороха.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest20Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest20No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest21"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 21/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Период менопаузы, «приливы»
                                            </h1>

                                            <p class="p-quest mb-10">Повышенное давление, приливы, потливость и т.д.
                                                (<strong>Мужчины на этот вопрос отвечают "НЕТ"</strong>).</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest21Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest21No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest22"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 22/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Проблемы с мочеиспусканием или заболевания мочевого пузыря
                                            </h1>

                                            <p class="p-quest mb-10">Мочеиспускание более 7 раз в день.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest22Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest22No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest23"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 23/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Чувствительная (истончённая) кожа
                                            </h1>

                                            <p class="p-quest mb-10">Ощущение стянутости после умывания, частые
                                                раздражения, возникновение красных пятен, чувство жжения, зуд,
                                                шелушение, реакция на использование новой косметики.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest23Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest23No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest24"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 24/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Выпадение волос или проблемы с кожей головы
                                            </h1>

                                            <p class="p-quest mb-10">Визуально замечаете увеличение количества выпавших
                                                волос во время мытья, в процессе расчесывания, на подушке после сна.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest24Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest24No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest25"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 25/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Боли в суставах, «хруст». Отечность. Онемение конечностей.
                                            </h1>

                                            <p class="p-quest mb-10">Визуально видите отеки и ощущаете боли в суставах,
                                                особенно после долгого сидения.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest25Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest25No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest26"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 26/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Отклонение от нормального веса
                                            </h1>

                                            <p class="p-quest mb-10">Вес снижается только при ограничениях в питании, во
                                                время диеты. Либо не идёт набор веса даже при усиленном питании.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest26Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest26No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest27"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 27/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Быстрая утомляемость
                                            </h1>

                                            <p class="p-quest mb-10">Нет сил, выносливости, требуется постоянный
                                                отдых.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest27Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest27No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest28"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 28/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Нарушение режима питания
                                            </h1>

                                            <p class="p-quest mb-10">Нерегулярное, неполноценное питание менее 3-х раз в
                                                день.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest28Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest28No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest29"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 29/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Длительное выздоровление после болезней
                                            </h1>

                                            <p class="p-quest mb-10">Период выздоровления от простудных заболеваний
                                                более 1 недели.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest29Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest29No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest30"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 30/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Нерегулярный стул
                                            </h1>

                                            <p class="p-quest mb-10">При 3-х разовом питании опорожнение кишечника менее
                                                2 раз в день и/или явные запоры.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest30Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest30No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest31"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 31/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Плохой аппетит
                                            </h1>

                                            <p class="p-quest mb-10">Вам приходится заставлять себя что-то скушать.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest31Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest31No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest32"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 32/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Истончённые и ломкие ногти (слоящиеся ногти)
                                            </h1>

                                            <p class="p-quest mb-10">Вам трудно отрастить ногти, ломаются при любом
                                                механическом воздействии.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest32Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest32No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest33"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 33/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Повреждённые волосы (сухие или тусклые)
                                            </h1>

                                            <p class="p-quest mb-10">Ломкость волоса в прикорневой зоне или 5-10 см от
                                                корня волоса, посеченные кончики.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest33Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest33No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest34"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 34/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Употребление жирной пищи
                                            </h1>

                                            <p class="p-quest mb-10">Употребление жареной, масляной, сильно жирной пищи
                                                более 2 раз в неделю.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest34Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest34No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest35"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 35/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Недостаток клетчатки в рационе
                                            </h1>

                                            <p class="p-quest mb-10">Употребление овощей, в т.ч. салатов - менее 2 раз в
                                                день.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest35Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest35No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest36"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 36/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Мышечный дискомфорт (боли, судороги)
                                            </h1>

                                            <p class="p-quest mb-10">Частые судороги по утрам, в воде, при ходьбе.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest36Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest36No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest37"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 37/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Неблагоприятная экология
                                            </h1>

                                            <p class="p-quest mb-10">Живете в мегаполисе, неподалеку заводы, фабрики,
                                                токсичное производство.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest37Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest37No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest38"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 38/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Дневная сонливость
                                            </h1>

                                            <p class="p-quest mb-10">Постоянно хочется днём спать.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest38Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest38No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest39"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 39/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Ежедневное потребление колы, кофе или крепкого чая
                                            </h1>

                                            <p class="p-quest mb-10">Пьёте более 2-х чашек колы, кофе или крепкого чая в
                                                день.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest39Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest39No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest40"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 40/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Чувствительность к химикатам, лекарствам, некоторой пище
                                            </h1>

                                            <p class="p-quest mb-10">Дискомфорт любого характера: зуд, одышка, отеки.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest40Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest40No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest41"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 41/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Грибковые поражения
                                            </h1>

                                            <p class="p-quest mb-10">Наличие белого налёта на языке по утрам, выделения
                                                у женщин, грибок на ногтях.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest41Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest41No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest42"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 42/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Слабость в мышцах или хрупкость костей
                                            </h1>

                                            <p class="p-quest mb-10">Частые переломы, периодически подкашиваются
                                                ноги.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest42Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest42No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest43"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 43/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Чувство тревоги
                                            </h1>

                                            <p class="p-quest mb-10">Вы постоянно переживаете и порой по пустякам.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest43Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest43No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest44"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 44/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Повышенная раздражительность, чрезмерная возбудимость
                                            </h1>

                                            <p class="p-quest mb-10">Раздражаетесь, злитесь и понимаете, что веских
                                                причин для этого нет.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest44Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest44No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest45"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 45/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Малоподвижный образ жизни, низкая физическая активность
                                            </h1>

                                            <p class="p-quest mb-10">Ведете сидячий образ жизни, малоподвижная работа,
                                                отсутствует какая-либо физическая активность.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest45Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest45No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest46"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 46/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Повышенное выделение мокроты (выделение слизи)
                                            </h1>

                                            <p class="p-quest mb-10">Кашель с мокротой по утрам и в течение дня,
                                                независимо от простуды, повышенное слюноотделение.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest46Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest46No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuest47"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <p class="p-quest-num">Вопрос: 47/47</p>

                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Большие поры на коже, повышенное потоотделение (угри)
                                            </h1>

                                            <p class="p-quest mb-10">Кожа жирная, крупные поры, угревая сыпь, иные
                                                высыпания.</p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="quest47Yes">Скорее ДА</v-btn>
                                                <v-btn class="btn-quest" @click="quest47No">Скорее НЕТ</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                class="card-test-quest"
                                v-if="blockQuestEnd"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-card-text
                                            class="mt-2 card-test-text-quest"
                                        >
                                            <h1
                                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                                style="font-weight: 600"
                                                class="h-quest mb-10"
                                            >
                                                Результаты теста обрабатываются
                                            </h1>

                                            <p class="p-quest mb-10">
                                                <strong>ВАЖНО!</strong>
                                                Для того, чтобы получить рекомендации по питанию и улучшению здоровья,
                                                <strong style="text-decoration: underline">отправьте номер телефона в ШАГЕ 2</strong>
                                            </p>

                                            <v-divider/>

                                            <v-card-actions class="footer-quest-form mt-4">
                                                <v-btn class="btn-quest mr-10" @click="questEnd">Понятно</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <base-material-card
                                color="warning"
                                class="card-test-result"
                                v-if="blockResultTest"
                            >
                                <template v-slot:heading>
                                    <div class="display-2 font-weight-light">
                                        Результаты теста
                                    </div>
                                    <div class="subtitle-1 font-weight-light">
                                    </div>
                                </template>
                                <v-card-text
                                    class="block-result-test mt-5"
                                >
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-center">
                                                    Система
                                                </th>
                                                <th class="text-center" style="width: 120px">
                                                    Очень хорошо
                                                </th>
                                                <th class="text-center" style="width: 120px">
                                                    Хорошо
                                                </th>
                                                <th class="text-center" style="width: 120px">
                                                    Зона риска
                                                </th>
                                                <th class="text-center" style="width: 120px">
                                                    Плохо
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/gkt-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Пищеварительная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Пищеварительная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-2</span>
                                                    <span class="table-result"
                                                          v-if="testResults.gktSys < 3"
                                                    >
                                                    {{ testResults.gktSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">3-4</span>
                                                    <span class="table-result"
                                                          v-if="testResults.gktSys > 2 && testResults.gktSys < 5"
                                                    >
                                                    {{ testResults.gktSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">5-9</span>
                                                    <span class="table-result"
                                                          v-if="testResults.gktSys > 4 && testResults.gktSys < 10"
                                                    >
                                                    {{ testResults.gktSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">10></span>
                                                    <span class="table-result"
                                                          v-if="testResults.gktSys > 9"
                                                    >
                                                    {{ testResults.gktSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/heart-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Сердечно-сосудистая система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Сердечно-сосудистая</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-2</span>
                                                    <span class="table-result"
                                                          v-if="testResults.heartSys < 3"
                                                    >
                                                    {{ testResults.heartSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">3</span>
                                                    <span class="table-result"
                                                          v-if="testResults.heartSys === 3"
                                                    >
                                                    {{ testResults.heartSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">4-7</span>
                                                    <span class="table-result"
                                                          v-if="testResults.heartSys > 3 && testResults.heartSys < 8"
                                                    >
                                                    {{ testResults.heartSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">8></span>
                                                    <span class="table-result"
                                                          v-if="testResults.heartSys > 7"
                                                    >
                                                    {{ testResults.heartSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/nerv-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Нервная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Нервная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-2</span>
                                                    <span class="table-result"
                                                          v-if="testResults.nervSys < 3"
                                                    >
                                                    {{ testResults.nervSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">3-5</span>
                                                    <span class="table-result"
                                                          v-if="testResults.nervSys > 2 && testResults.nervSys < 6"
                                                    >
                                                    {{ testResults.nervSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">6-9</span>
                                                    <span class="table-result"
                                                          v-if="testResults.nervSys > 5 && testResults.nervSys < 10"
                                                    >
                                                    {{ testResults.nervSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">10></span>
                                                    <span class="table-result"
                                                          v-if="testResults.nervSys > 9"
                                                    >
                                                    {{ testResults.nervSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/imun-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Иммунная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Иммунная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-2</span>
                                                    <span class="table-result"
                                                          v-if="testResults.imunSys < 3"
                                                    >
                                                    {{ testResults.imunSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">3-4</span>
                                                    <span class="table-result"
                                                          v-if="testResults.imunSys > 2 && testResults.imunSys < 5"
                                                    >
                                                    {{ testResults.imunSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">5-7</span>
                                                    <span class="table-result"
                                                          v-if="testResults.imunSys > 4 && testResults.imunSys < 8"
                                                    >
                                                    {{ testResults.imunSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">8></span>
                                                    <span class="table-result"
                                                          v-if="testResults.imunSys > 7"
                                                    >
                                                    {{ testResults.imunSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/breath-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Дыхательная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Дыхательная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0</span>
                                                    <span class="table-result"
                                                          v-if="testResults.breathSys === 0"
                                                    >
                                                    {{ testResults.breathSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">1-3</span>
                                                    <span class="table-result"
                                                          v-if="testResults.breathSys > 0 && testResults.breathSys < 4"
                                                    >
                                                    {{ testResults.breathSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">4-5</span>
                                                    <span class="table-result"
                                                          v-if="testResults.breathSys > 3 && testResults.breathSys < 6"
                                                    >
                                                    {{ testResults.breathSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">6></span>
                                                    <span class="table-result"
                                                          v-if="testResults.breathSys > 5"
                                                    >
                                                    {{ testResults.breathSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/mocha-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Мочеполовая система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Мочеполовая</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0</span>
                                                    <span class="table-result"
                                                          v-if="testResults.mochaSys === 0"
                                                    >
                                                    {{ testResults.mochaSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">1</span>
                                                    <span class="table-result"
                                                          v-if="testResults.mochaSys === 1"
                                                    >
                                                    {{ testResults.mochaSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">2-4</span>
                                                    <span class="table-result"
                                                          v-if="testResults.mochaSys > 1 && testResults.mochaSys < 5"
                                                    >
                                                    {{ testResults.mochaSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">5></span>
                                                    <span class="table-result"
                                                          v-if="testResults.mochaSys > 4"
                                                    >
                                                    {{ testResults.mochaSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/endo-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Эндокринная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Эндокринная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-2</span>
                                                    <span class="table-result"
                                                          v-if="testResults.endoSys < 3"
                                                    >
                                                    {{ testResults.endoSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">3-5</span>
                                                    <span class="table-result"
                                                          v-if="testResults.endoSys > 2 && testResults.endoSys < 6"
                                                    >
                                                    {{ testResults.endoSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">6-9</span>
                                                    <span class="table-result"
                                                          v-if="testResults.endoSys > 5 && testResults.endoSys < 10"
                                                    >
                                                    {{ testResults.endoSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">10></span>
                                                    <span class="table-result"
                                                          v-if="testResults.endoSys > 9"
                                                    >
                                                    {{ testResults.endoSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/move-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Опорно-двигательная система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Опорно-двигательная</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-1</span>
                                                    <span class="table-result"
                                                          v-if="testResults.opdvigSys < 2"
                                                    >
                                                    {{ testResults.opdvigSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">2-3</span>
                                                    <span class="table-result"
                                                          v-if="testResults.opdvigSys > 1 && testResults.opdvigSys < 4"
                                                    >
                                                    {{ testResults.opdvigSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">4-8</span>
                                                    <span class="table-result"
                                                          v-if="testResults.opdvigSys > 3 && testResults.opdvigSys < 9"
                                                    >
                                                    {{ testResults.opdvigSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">9></span>
                                                    <span class="table-result"
                                                          v-if="testResults.opdvigSys > 8"
                                                    >
                                                    {{ testResults.opdvigSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr class="tbl-tr">
                                                <td class="tbl-sys">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                class="sys-icon"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img src="/img/testsysicons/skin-icon.png" alt="">
                                                            </span>
                                                        </template>
                                                        <span>Кожа система</span>
                                                    </v-tooltip>
                                                    <p class="sys-text">Кожа</p>
                                                </td>
                                                <td class="tbl-very-good">
                                                    <span class="table-label">0-1</span>
                                                    <span class="table-result"
                                                          v-if="testResults.skinSys < 2"
                                                    >
                                                    {{ testResults.skinSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-good">
                                                    <span class="table-label">2-3</span>
                                                    <span class="table-result"
                                                          v-if="testResults.skinSys > 1 && testResults.skinSys < 4"
                                                    >
                                                    {{ testResults.skinSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-risk">
                                                    <span class="table-label">4-6</span>
                                                    <span class="table-result"
                                                          v-if="testResults.skinSys > 3 && testResults.skinSys < 7"
                                                    >
                                                    {{ testResults.skinSys }}
                                                </span>
                                                </td>
                                                <td class="tbl-bad">
                                                    <span class="table-label">7></span>
                                                    <span class="table-result"
                                                          v-if="testResults.skinSys > 6"
                                                    >
                                                    {{ testResults.skinSys }}
                                                </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>

                                <v-card-text class="result-answer-block">
                                    <div class="result-answer">
                                        <h4 class="mt-7 mb-5 h4-test-page">Шаг 1: Ознакомься с результатами теста</h4>
                                        <v-simple-table class="mb-10">
                                            <template v-slot:default>
                                                <tbody>
                                                <tr>
                                                    <td style="width: 450px; background-color: #bcfea4; padding-top: 10px; padding-bottom: 10px">
                                                        Системы, которые <strong style="text-decoration: underline">работают
                                                        очень хорошо</strong>:
                                                        <ul>
                                                            <li class="span-res-sys" v-if="testResults.gktSys < 3">
                                                                пищеварительная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.heartSys < 3">
                                                                сердечно-сосудистая
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.nervSys < 3">
                                                                нервная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.imunSys < 3">
                                                                иммунная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.breathSys < 1">
                                                                дыхательная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.mochaSys < 1">
                                                                мочеполовая
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.endoSys < 3">
                                                                эндокринная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.opdvigSys < 2">
                                                                опорно-двигательная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.skinSys < 2">
                                                                кожа
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 450px; background-color: #b7e0fe; padding-top: 10px; padding-bottom: 10px">
                                                        Системы, которые <strong style="text-decoration: underline">работают
                                                        хорошо</strong>, но уже начался сдвиг в ЗОНУ РИСКА:
                                                        <ul>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.gktSys > 2 && testResults.gktSys < 5">
                                                                пищеварительная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.heartSys === 3">
                                                                сердечно-сосудистая
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.nervSys > 2 && testResults.nervSys < 6">
                                                                нервная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.imunSys > 2 && testResults.imunSys < 5">
                                                                иммунная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.breathSys > 0 && testResults.breathSys < 4">
                                                                дыхательная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.mochaSys === 1">
                                                                мочеполовая
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.endoSys > 2 && testResults.endoSys < 6">
                                                                эндокринная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.opdvigSys > 1 && testResults.opdvigSys < 4">
                                                                опорно-двигательная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.skinSys > 1 && testResults.skinSys < 4">
                                                                кожа
                                                            </li>
                                                        </ul>
                                                        Если ничего не менять в питании и образе жизни, то со временем
                                                        эти системы перейдут в ЗОНУ РИСКА.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 450px; background-color: #feffb3; padding-top: 10px; padding-bottom: 10px">
                                                        Системы, которые находятся в самой опасной <strong
                                                        style="text-decoration: underline">ЗОНЕ РИСКА</strong>:
                                                        <ul>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.gktSys > 4 && testResults.gktSys < 10">
                                                                пищеварительная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.heartSys > 3 && testResults.heartSys < 8">
                                                                сердечно-сосудистая
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.nervSys > 5 && testResults.nervSys < 10">
                                                                нервная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.imunSys > 4 && testResults.imunSys < 8">
                                                                иммунная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.breathSys > 3 && testResults.breathSys < 6">
                                                                дыхательная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.mochaSys > 1 && testResults.mochaSys < 5">
                                                                мочеполовая
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.endoSys > 5 && testResults.endoSys < 10">
                                                                эндокринная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.opdvigSys > 3 && testResults.opdvigSys < 9">
                                                                опорно-двигательная
                                                            </li>
                                                            <li class="span-res-sys"
                                                                v-if="testResults.skinSys > 3 && testResults.skinSys < 7">
                                                                кожа
                                                            </li>
                                                        </ul>
                                                        В любой момент стресс может вызвать сбой какой-либо системы в
                                                        организме, что может привести к серьезным заболеваниям! <strong
                                                        style="text-decoration: underline">Медлить нельзя! Надо срочно
                                                        заняться своим здоровьем!</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 450px; background-color: #ffc5c4; padding-top: 10px; padding-bottom: 10px">
                                                        <strong style="text-decoration: underline">Системы
                                                            поражены</strong>, или в ближайшее время могут проявиться
                                                        заболевания:
                                                        <ul>
                                                            <li class="span-res-sys" v-if="testResults.gktSys > 9">
                                                                пищеварительная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.heartSys > 7">
                                                                сердечно-сосудистая
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.nervSys > 9">
                                                                нервная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.imunSys > 7">
                                                                иммунная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.breathSys > 5">
                                                                дыхательная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.mochaSys > 4">
                                                                мочеполовая
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.endoSys > 9">
                                                                эндокринная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.opdvigSys > 8">
                                                                опорно-двигательная
                                                            </li>
                                                            <li class="span-res-sys" v-if="testResults.skinSys > 6">
                                                                кожа
                                                            </li>
                                                        </ul>
                                                        Вам <strong style="text-decoration: underline">срочно необходимо
                                                        обратиться к специалисту</strong>, если вы до сих пор этого не
                                                        сделали!
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>

                                        <h4 class="mt-7 mb-5 h4-test-page">Шаг 2: Получи рекомендации</h4>
                                        <p style="text-align: center">Укажи номер телефона и получи:</p>
                                        <p class="test-lid-bullets text-center">
                                            <v-icon
                                                color="blue"
                                                class="mr-2"
                                            >
                                                mdi-check-bold
                                            </v-icon>
                                            развернутые рекомендации специалистов
                                            <strong>по каждой из 9 систем организма</strong>
                                        </p>

                                        <v-form v-if="firstStepBtn">
                                            <v-container class="py-0">
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        lg="6"
                                                        md="6"
                                                        sm="8"
                                                        class="field-relative"
                                                        style="margin-left: auto; margin-right: auto"
                                                    >
                                                        <v-text-field
                                                            label="Телефон"
                                                            prepend-icon="mdi-phone"
                                                            type="text"
                                                            v-model.trim="newProspect.phone"
                                                            :error-messages="phoneErrors"
                                                            @input="$v.newProspect.phone.$touch()"
                                                            @blur="$v.newProspect.phone.$touch()"
                                                        />
                                                        <div class="invalid-feedback" v-if="errors.phone">
                                                            {{ errors.phone[0] }}
                                                        </div>

                                                        <p style="text-align: center">
                                                            <v-btn
                                                                color="success"
                                                                class="mr-0"
                                                                @click="addProspectTest"
                                                            >
                                                                Получить рекомендации
                                                            </v-btn>
                                                        </p>
                                                        <p class="privacy-text">
                                                            Оставляя заявку, вы даете свое
                                                            <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                            и принимаете
                                                            <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>

                                        <div
                                            v-if="successAddClient"
                                            class="blockAccess"
                                        >
                                            <h3 class="mb-3">Выбери мессенджер</h3>
                                            <p style="font-size: 14px">
                                                <strong>
                                                    И получи рекомендации по питанию для оздоровления организма
                                                </strong>
                                            </p>

                                            <v-btn
                                                color="red"
                                                class="mr-0"
                                                @click="goMiniLanding"
                                            >
                                                Выбрать мессенджер
                                            </v-btn>

                                        </div>
                                    </div>
                                </v-card-text>
                                <v-divider class="mt-10"></v-divider>
                                <div class="footer-test">
                                    &copy; {{ new Date().getFullYear() }}, newlvl.net, All right reserved
                                </div>
                            </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";

export default {
    name: "HealthTestOld",
    mixins: [validationMixin],
    data: () => ({
        newProspect: {
            phone: '',
            partner: '',
            instrument: 'Тестирование (сайт)',
            step: 'Новый',
            test_results: ''
        },
        firstStepBtn: true,
        successAddClient: false,
        link_widget: '',
        cookieShow: true,
        // htmlToPdfOptions: {
        //     margin: 5,
        // },
        testResults: {
            gktSys: 0,
            heartSys: 0,
            nervSys: 0,
            imunSys: 0,
            breathSys: 0,
            mochaSys: 0,
            endoSys: 0,
            opdvigSys: 0,
            skinSys: 0,
        },
        errors: {},
        loading: false,
        blockTestStart: true,
        blockQuest0: false,
        blockQuest1: false,
        blockQuest2: false,
        blockQuest3: false,
        blockQuest4: false,
        blockQuest5: false,
        blockQuest6: false,
        blockQuest7: false,
        blockQuest8: false,
        blockQuest9: false,
        blockQuest10: false,
        blockQuest11: false,
        blockQuest12: false,
        blockQuest13: false,
        blockQuest14: false,
        blockQuest15: false,
        blockQuest16: false,
        blockQuest17: false,
        blockQuest18: false,
        blockQuest19: false,
        blockQuest20: false,
        blockQuest21: false,
        blockQuest22: false,
        blockQuest23: false,
        blockQuest24: false,
        blockQuest25: false,
        blockQuest26: false,
        blockQuest27: false,
        blockQuest28: false,
        blockQuest29: false,
        blockQuest30: false,
        blockQuest31: false,
        blockQuest32: false,
        blockQuest33: false,
        blockQuest34: false,
        blockQuest35: false,
        blockQuest36: false,
        blockQuest37: false,
        blockQuest38: false,
        blockQuest39: false,
        blockQuest40: false,
        blockQuest41: false,
        blockQuest42: false,
        blockQuest43: false,
        blockQuest44: false,
        blockQuest45: false,
        blockQuest46: false,
        blockQuest47: false,
        blockQuestEnd: false,
        blockResultTest: false
    }),
    metaInfo: {
        title: 'Тест Хорошее самочувствие',
        meta: [
            {
                vmid: 'description',
                property: 'description',
                content: 'Пройди тест, который покажет, в какой области здоровья есть проблемы, и получи рекомендации специалистов по укреплению и поддержанию здоровья'
            },
            {vmid: 'og:title', property: 'og:title', content: 'Тест Хорошее самочувствие'},
            {
                vmid: 'og:description',
                property: 'og:description',
                content: 'Пройди тест, который покажет, в какой области здоровья есть проблемы, и получи рекомендации специалистов по укреплению и поддержанию здоровья'
            },
            {vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png'},
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push({name: 'HealthTestOld', query: {inst: 'test', partner: partnerNum}})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUser'
        }),
        dataUser() {
            return this.userData
        },
        currentDate() {
            let curDate = new Date().getDate()
            if (curDate <= 9) {
                curDate = '0' + curDate
            }

            let curMonth = new Date().getMonth() + 1
            if (curMonth <= 9) {
                curMonth = '0' + curMonth
            }

            const curYear = new Date().getFullYear()

            return curDate + '.' + curMonth + '.' + curYear
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.newProspect.phone.$dirty) return errors
            !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
            !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
            return errors
        }
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            createProspect: 'user/addClient',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        clickBtnTest() {
            this.blockTestStart = false
            this.blockQuest0 = true
        },
        quest0() {
            this.blockQuest0 = false
            this.blockQuest1 = true
        },
        quest1Yes() {
            this.blockQuest1 = false
            this.blockQuest2 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
        },
        quest1No() {
            this.blockQuest1 = false
            this.blockQuest2 = true
        },
        quest2Yes() {
            this.blockQuest2 = false
            this.blockQuest3 = true
            this.testResults.imunSys += 1
            this.testResults.skinSys += 1
        },
        quest2No() {
            this.blockQuest2 = false
            this.blockQuest3 = true
        },
        quest3Yes() {
            this.blockQuest3 = false
            this.blockQuest4 = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
            this.testResults.mochaSys += 1
        },
        quest3No() {
            this.blockQuest3 = false
            this.blockQuest4 = true
        },
        quest4Yes() {
            this.blockQuest4 = false
            this.blockQuest5 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
        },
        quest4No() {
            this.blockQuest4 = false
            this.blockQuest5 = true
        },
        quest5Yes() {
            this.blockQuest5 = false
            this.blockQuest6 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
        },
        quest5No() {
            this.blockQuest5 = false
            this.blockQuest6 = true
        },
        quest6Yes() {
            this.blockQuest6 = false
            this.blockQuest7 = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
            this.testResults.endoSys += 1
        },
        quest6No() {
            this.blockQuest6 = false
            this.blockQuest7 = true
        },
        quest7Yes() {
            this.blockQuest7 = false
            this.blockQuest8 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
        },
        quest7No() {
            this.blockQuest7 = false
            this.blockQuest8 = true
        },
        quest8Yes() {
            this.blockQuest8 = false
            this.blockQuest9 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest8No() {
            this.blockQuest8 = false
            this.blockQuest9 = true
        },
        quest9Yes() {
            this.blockQuest9 = false
            this.blockQuest10 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest9No() {
            this.blockQuest9 = false
            this.blockQuest10 = true
        },
        quest10Yes() {
            this.blockQuest10 = false
            this.blockQuest11 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
        },
        quest10No() {
            this.blockQuest10 = false
            this.blockQuest11 = true
        },
        quest11Yes() {
            this.blockQuest11 = false
            this.blockQuest12 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.mochaSys += 1
            this.testResults.skinSys += 1
        },
        quest11No() {
            this.blockQuest11 = false
            this.blockQuest12 = true
        },
        quest12Yes() {
            this.blockQuest12 = false
            this.blockQuest13 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
        },
        quest12No() {
            this.blockQuest12 = false
            this.blockQuest13 = true
        },
        quest13Yes() {
            this.blockQuest13 = false
            this.blockQuest14 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest13No() {
            this.blockQuest13 = false
            this.blockQuest14 = true
        },
        quest14Yes() {
            this.blockQuest14 = false
            this.blockQuest15 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
        },
        quest14No() {
            this.blockQuest14 = false
            this.blockQuest15 = true
        },
        quest15Yes() {
            this.blockQuest15 = false
            this.blockQuest16 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.skinSys += 1
        },
        quest15No() {
            this.blockQuest15 = false
            this.blockQuest16 = true
        },
        quest16Yes() {
            this.blockQuest16 = false
            this.blockQuest17 = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.testResults.skinSys += 1
        },
        quest16No() {
            this.blockQuest16 = false
            this.blockQuest17 = true
        },
        quest17Yes() {
            this.blockQuest17 = false
            this.blockQuest18 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest17No() {
            this.blockQuest17 = false
            this.blockQuest18 = true
        },
        quest18Yes() {
            this.blockQuest18 = false
            this.blockQuest19 = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
        },
        quest18No() {
            this.blockQuest18 = false
            this.blockQuest19 = true
        },
        quest19Yes() {
            this.blockQuest19 = false
            this.blockQuest20 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest19No() {
            this.blockQuest19 = false
            this.blockQuest20 = true
        },
        quest20Yes() {
            this.blockQuest20 = false
            this.blockQuest21 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest20No() {
            this.blockQuest20 = false
            this.blockQuest21 = true
        },
        quest21Yes() {
            this.blockQuest21 = false
            this.blockQuest22 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest21No() {
            this.blockQuest21 = false
            this.blockQuest22 = true
        },
        quest22Yes() {
            this.blockQuest22 = false
            this.blockQuest23 = true
            this.testResults.mochaSys += 1
        },
        quest22No() {
            this.blockQuest22 = false
            this.blockQuest23 = true
        },
        quest23Yes() {
            this.blockQuest23 = false
            this.blockQuest24 = true
            this.testResults.skinSys += 1
        },
        quest23No() {
            this.blockQuest23 = false
            this.blockQuest24 = true
        },
        quest24Yes() {
            this.blockQuest24 = false
            this.blockQuest25 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest24No() {
            this.blockQuest24 = false
            this.blockQuest25 = true
        },
        quest25Yes() {
            this.blockQuest25 = false
            this.blockQuest26 = true
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.opdvigSys += 1
        },
        quest25No() {
            this.blockQuest25 = false
            this.blockQuest26 = true
        },
        quest26Yes() {
            this.blockQuest26 = false
            this.blockQuest27 = true
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest26No() {
            this.blockQuest26 = false
            this.blockQuest27 = true
        },
        quest27Yes() {
            this.blockQuest27 = false
            this.blockQuest28 = true
            this.testResults.heartSys += 1
            this.testResults.breathSys += 1
            this.testResults.opdvigSys += 1
        },
        quest27No() {
            this.blockQuest27 = false
            this.blockQuest28 = true
        },
        quest28Yes() {
            this.blockQuest28 = false
            this.blockQuest29 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.skinSys += 1
        },
        quest28No() {
            this.blockQuest28 = false
            this.blockQuest29 = true
        },
        quest29Yes() {
            this.blockQuest29 = false
            this.blockQuest30 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
        },
        quest29No() {
            this.blockQuest29 = false
            this.blockQuest30 = true
        },
        quest30Yes() {
            this.blockQuest30 = false
            this.blockQuest31 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.skinSys += 1
        },
        quest30No() {
            this.blockQuest30 = false
            this.blockQuest31 = true
        },
        quest31Yes() {
            this.blockQuest31 = false
            this.blockQuest32 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest31No() {
            this.blockQuest31 = false
            this.blockQuest32 = true
        },
        quest32Yes() {
            this.blockQuest32 = false
            this.blockQuest33 = true
            this.testResults.gktSys += 1
            this.testResults.opdvigSys += 1
        },
        quest32No() {
            this.blockQuest32 = false
            this.blockQuest33 = true
        },
        quest33Yes() {
            this.blockQuest33 = false
            this.blockQuest34 = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
        },
        quest33No() {
            this.blockQuest33 = false
            this.blockQuest34 = true
        },
        quest34Yes() {
            this.blockQuest34 = false
            this.blockQuest35 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
        },
        quest34No() {
            this.blockQuest34 = false
            this.blockQuest35 = true
        },
        quest35Yes() {
            this.blockQuest35 = false
            this.blockQuest36 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
        },
        quest35No() {
            this.blockQuest35 = false
            this.blockQuest36 = true
        },
        quest36Yes() {
            this.blockQuest36 = false
            this.blockQuest37 = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.opdvigSys += 1
        },
        quest36No() {
            this.blockQuest36 = false
            this.blockQuest37 = true
        },
        quest37Yes() {
            this.blockQuest37 = false
            this.blockQuest38 = true
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
        },
        quest37No() {
            this.blockQuest37 = false
            this.blockQuest38 = true
        },
        quest38Yes() {
            this.blockQuest38 = false
            this.blockQuest39 = true
            this.testResults.heartSys += 1
            this.testResults.endoSys += 1
        },
        quest38No() {
            this.blockQuest38 = false
            this.blockQuest39 = true
        },
        quest39Yes() {
            this.blockQuest39 = false
            this.blockQuest40 = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest39No() {
            this.blockQuest39 = false
            this.blockQuest40 = true
        },
        quest40Yes() {
            this.blockQuest40 = false
            this.blockQuest41 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
        },
        quest40No() {
            this.blockQuest40 = false
            this.blockQuest41 = true
        },
        quest41Yes() {
            this.blockQuest41 = false
            this.blockQuest42 = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.testResults.mochaSys += 1
        },
        quest41No() {
            this.blockQuest41 = false
            this.blockQuest42 = true
        },
        quest42Yes() {
            this.blockQuest42 = false
            this.blockQuest43 = true
            this.testResults.gktSys += 1
            this.testResults.opdvigSys += 1
        },
        quest42No() {
            this.blockQuest42 = false
            this.blockQuest43 = true
        },
        quest43Yes() {
            this.blockQuest43 = false
            this.blockQuest44 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
        },
        quest43No() {
            this.blockQuest43 = false
            this.blockQuest44 = true
        },
        quest44Yes() {
            this.blockQuest44 = false
            this.blockQuest45 = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
        },
        quest44No() {
            this.blockQuest44 = false
            this.blockQuest45 = true
        },
        quest45Yes() {
            this.blockQuest45 = false
            this.blockQuest46 = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
        },
        quest45No() {
            this.blockQuest45 = false
            this.blockQuest46 = true
        },
        quest46Yes() {
            this.blockQuest46 = false
            this.blockQuest47 = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
        },
        quest46No() {
            this.blockQuest46 = false
            this.blockQuest47 = true
        },
        quest47Yes() {
            this.blockQuest47 = false
            this.blockQuestEnd = true
            this.testResults.skinSys += 1
        },
        quest47No() {
            this.blockQuest47 = false
            this.blockQuestEnd = true
        },
        questEnd() {
            this.blockQuestEnd = false
            this.blockResultTest = true
        },
        addProspectTest() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.newProspect.test_results = this.testResults.gktSys + ',' + this.testResults.heartSys + ',' + this.testResults.nervSys + ',' + this.testResults.imunSys + ',' + this.testResults.breathSys + ',' + this.testResults.mochaSys + ',' + this.testResults.endoSys + ',' + this.testResults.opdvigSys + ',' + this.testResults.skinSys
                this.createProspect(this.newProspect)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.firstStepBtn = false
                            this.successAddClient = true
                            this.link_widget = 'https://mnlp.cc/mini?domain=relifeteam&id=257&utm_source=' + response.data.lr_number + '&utm_medium=' + response.data.prospect_id
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
        goMiniLanding() {
            window.open(this.link_widget, 'blank')
        }
    },
    mounted() {
        this.newProspect.partner = this.$route.query.partner
        this.newProspect.inst = this.$route.query.inst
        // this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
    },
    validations: {
        newProspect: {
            phone: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(30)
            }
        }
    }
}
</script>

<style lang="sass">
.container.landing
    max-width: 1140px !important
    padding: 0

.sub-title-test
    font-size: 24px
    line-height: 36px !important

.card-test
    border-radius: 20px !important
    padding-right: 0 !important
    padding-left: 50px !important
    background: linear-gradient(to top, #84ad84, #fff)

.card-test-quest
    border-radius: 20px !important
    max-width: 750px
    margin-left: auto
    margin-right: auto

.card-test-text-quest
    padding: 0 30px

.card-test-result
    border-radius: 20px !important

.tbl-very-good
    text-align: center
    background-color: #bcfea4
    position: relative

.tbl-good
    text-align: center
    background-color: #b7e0fe
    position: relative

.tbl-risk
    text-align: center
    background-color: #feffb3
    position: relative

.tbl-bad
    text-align: center
    background-color: #ffc5c4
    position: relative

.table-label
    color: #666
    font-size: 13px
    position: absolute
    left: 5px
    top: 0

.table-result
    position: absolute
    left: calc(47.5% - 18px)
    font-size: 22px
    background: #fff
    border-radius: 50%
    width: 36px
    height: 36px
    text-align: center
    border: 2px solid #c00
    bottom: 5px
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.3s
    font-weight: 600
    color: #333
    z-index: 300

.p-block
    color: #333333
    font-size: 16px
    line-height: 22px
    margin-bottom: 20px !important

.blockAccess
    text-align: center
    max-width: 760px
    margin: 40px auto 0
    border: 1px solid rgb(105, 219, 105)
    border-radius: 10px
    padding: 20px

.span-res-sys
    font-weight: 500

.btn-test
    height: 60px !important
    border-radius: 10px
    color: #333 !important
    font-weight: 600
    font-size: 20px !important
    text-transform: uppercase

    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-flow: row nowrap
    flex-flow: row nowrap
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    position: relative
    overflow: hidden
    -webkit-transition: ease 0.3s
    transition: ease 0.3s

.btn-test:after
    content: ""
    display: block
    width: 30px
    height: 300px
    margin-left: 60px
    background: #fff
    filter: blur(30px)
    position: absolute
    left: -40px
    top: -150px
    z-index: 1
    -webkit-transform: rotate(45deg)
    transform: rotate(45deg)
    -webkit-transition: all 0.1s
    transition: all 0.1s
    -webkit-animation-name: slideme
    animation-name: slideme
    -webkit-animation-duration: 3s
    animation-duration: 3s
    -webkit-animation-delay: 0.05s
    animation-delay: 0.05s
    -webkit-animation-timing-function: ease-in-out
    animation-timing-function: ease-in-out
    -webkit-animation-iteration-count: infinite
    animation-iteration-count: infinite

@-webkit-keyframes slideme
    0%
        left: -30px
        margin-left: 0
    30%
        left: 110%
        margin-left: 80px
    100%
        left: 110%
        margin-left: 80px

.h-quest
    color: #659d83

.p-quest
    color: #333333
    line-height: 26px !important

.p-quest-grey
    color: #999999
    font-size: 14px

.p-quest-num
    color: #999999
    font-size: 14px

.btn-quest
    background-color: #659d83 !important
    font-size: 18px !important
    border-radius: 6px

.h1-test-page
    text-align: center
    text-transform: uppercase
    line-height: 28px

.h3-test-page
    text-align: center
    text-transform: uppercase

.h4-test-page
    text-align: center
    text-transform: uppercase

.test-lid-bullets
    list-style-type: none
    text-align: left
    max-width: 900px
    margin-right: auto
    margin-left: auto

.test-lid-bullets li
    margin-bottom: 20px
    line-height: 24px
    padding-left: 30px
    position: relative

.test-lid-bullets li i
    position: absolute !important
    left: 0
    top: 3px

.card-test-result
    padding: 12px

.footer-test
    height: 100px
    color: #999
    padding-top: 40px
    text-align: center

.sys-icon
    display: none
    text-align: center
    padding-top: 7px

.tbl-sys .v-tooltip
    z-index: 999

.sys-icon img
    width: 32px

.sys-text
    margin: 0 !important

@media(max-width: 960px)
    .right-start-test
        display: none

    .p-block
        font-size: 16px

    .btn-test
        font-size: 18px !important

@media(max-width: 800px)
    .card-test
        padding-left: 30px !important

@media(max-width: 600px)
    .card-test
        padding-left: 20px !important

    .h-quest
        line-height: 1.3em !important

    .btn-test
        font-size: 16px !important

    .btn-quest:first-child
        margin-right: 0 !important

    .btn-quest
        font-size: 14px !important

    .footer-quest-form
        padding-right: 0
        padding-left: 0

    .sub-title-test
        font-size: 18px
        line-height: 28px !important

@media(max-width: 480px)
    .card-test
        padding-left: 10px !important

    .block-result-test, .result-answer-block
        padding-left: 0 !important
        padding-right: 0 !important

    .sys-icon
        display: block

    .sys-text
        display: none
</style>